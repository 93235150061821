<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">{{logo_title}}</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 全屏显示 -->
                <!-- <div class="btn-fullscreen" @click="handleFullScreen">
                    <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
                        <i class="el-icon-rank"></i>
                    </el-tooltip>
                </div> -->
                <!-- 消息中心 -->
                <!-- <div class="btn-bell">
                    <el-tooltip
                        effect="dark"
                        :content="message?`有${message}条未读消息`:`消息中心`"
                        placement="bottom"
                    >
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div> -->
                <!-- 用户头像 -->
                <!-- <div class="user-avator">
                    <img src="../../assets/img/img.jpg" />
                </div> -->
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="fixedpassword">修改密码</el-dropdown-item>
                        <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                    
                </el-dropdown>
            </div>
        </div>
        <el-dialog :visible.sync="fixedPasswordVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="passwordForm" :model="password_form" :rules="password_rules" label-width="120px">
                <el-form-item label="旧密码：" prop="password">
                    <el-input v-model="password_form.password" @input="onInput()"></el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="newpassword">
                    <el-input v-model="password_form.newpassword" @input="onInput()"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer self-dialog-footer">
                <el-button type="primary" @click="savePassword('passwordForm')">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import bus from '../common/bus';
import http from '../../api/manageApiConfig'
import totalmanage from '../../api/getapi/totalmanage'
export default {
    data() {
        return {
            logo_title: '家庭中医馆-消息管理后台',
            ms_referrer: '',
            collapse: false,
            fullscreen: false,
            name: 'linxin',
            message: 2,
            fixedPasswordVisible: false,
            password_form: {},
            password_rules:{
                password: [{ required: true, message: "请填写旧密码", trigger: "blur" }],
                newpassword: [{ required: true, message: "请填写新密码", trigger: "blur" }]
            }
        };
    },
    computed: {
        username() {
            let nickname = localStorage.getItem('ms_nickname');
            return nickname ? nickname : this.name;
        }
    },
    created(){
        if(this.getQueryString('t')){
            this.logo_title = '家庭中医馆后台管理系统'
        }
    },
    methods: {
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;

        },
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            let that = this;
            if (command == 'loginout') {
                let params = {
                    user_id: localStorage.getItem('ms_id'),
                    token: localStorage.getItem('ms_token')
                }
                http.post("admin/logout", params).then(function(res) {
                    if (res.data.status_code == 200) {
                        localStorage.removeItem('ms_username');
                        localStorage.removeItem('ms_token');
                        localStorage.removeItem('ms_nickname');
                        localStorage.removeItem('ms_role');
                        localStorage.removeItem('ms_id');
                        if(that.getQueryString('t')){
                            let url = totalmanage + '/outlogin?back_url=' + window.location.href;
                            window.location.href = url
                        }else{
                            that.$router.push('/login');
                        }
                    }else{
                        that.$message.error(res.data.msg);
                    }
                });
            }else if(command == 'fixedpassword'){
                that.password_form = {
                    password: '',
                    newpassword: ''
                }
                that.fixedPasswordVisible = true;
            }
        },
        onInput(){
            this.$forceUpdate();
        },
        savePassword(formName){
            let that = this;
            that.$refs[formName].validate(valid => {
                if (valid) {
                    let params = {
                        user_id: localStorage.getItem('ms_id'),
                        username: localStorage.getItem('ms_username'),
                        nickname: localStorage.getItem('ms_nickname'),
                        password: that.password_form.password,
                        new_password: that.password_form.newpassword
                    }
                    http.put("admin/self", params).then(function(res) {
                        if (res.data.status_code == 200) {
                            that.$message.success(`修改成功`);
                            localStorage.removeItem('ms_username');
                            localStorage.removeItem('ms_token');
                            localStorage.removeItem('ms_nickname');
                            localStorage.removeItem('ms_role');
                            localStorage.removeItem('ms_id');
                            if(that.getQueryString('t')){
                                let url = totalmanage + '/outlogin?back_url=' + window.location.href;
                                window.location.href = url
                            }else{
                                that.$router.push('/login');
                            }
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    });
                    that.fixedPasswordVisible = false;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        // 侧边栏折叠
        collapseChage() {
            this.collapse = !this.collapse;
            bus.$emit('collapse', this.collapse);
        },
        // 全屏事件
        handleFullScreen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>
<style scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.header .logo {
    float: left;
    width: 250px;
    line-height: 70px;
}
.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
.self-dialog-footer{
    display: block;
    text-align: center;
}
</style>
